import React, { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { Layout } from "../../components/Layout/Layout";
import Ue from "../../assets/images/ue.png";

import styles from "./WordsPage.module.scss";
import {
  fetchGestures,
  setCurrentGesture,
} from "../../store/Gestures/gesturesSlice";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Gesture } from "../../api/types";

export const WordsPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { gestures, loading } = useSelector(
    (state: RootState) => state.gestures
  );

  useEffect(() => {
    if (gestures.length === 0) {
      dispatch(fetchGestures());
    }
  }, [dispatch, gestures.length]);

  const handleClick = (gesture: Gesture) => {
    dispatch(setCurrentGesture(gesture));
    navigate("/recorder");
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <div className={styles.historyWrapper}>
            <p>
              Miguś ruszył dalej, aż dotarł do miasta, gdzie zauważył dwoje
              dzieci, które pokłóciły się o zabawkę. Lisek podszedł do nich i
              zapytał, co się stało. Jedno z dzieci, Zosia, powiedziało: "To
              moja zabawka!" A drugie, Kuba, odpowiedziało: "Nie, to moja!".
            </p>
            <p>
              Miguś spojrzał na dzieci i zaproponował: "Czy moglibyście
              przeprosić się nawzajem i podzielić się zabawką? Przepraszam jest
              bardzo ważnym słowem." Zosia i Kuba zastanowili się chwilę, a
              potem powiedzieli do siebie: "Przepraszam, nie chciałem cię
              urazić." Potem razem bawili się zabawką, śmiejąc się i ciesząc
              wspólną zabawą.
            </p>
            <p>
              Na koniec swojej podróży Miguś dotarł do zamku, gdzie odbywał się
              wielki bal. Podczas tańca Miguś zauważył starszą damę, która miała
              problem z przejściem przez tłum. Miguś podszedł do niej i
              powiedział: "Proszę, pozwól mi ci pomóc." Pomógł damie przejść
              bezpiecznie na drugą stronę sali. Starsza dama uśmiechnęła się i
              powiedziała: "Dziękuję, młody lisku. Jesteś bardzo uprzejmy".
            </p>
            <p>
              Kiedy Miguś wrócił do swojego królestwa, czuła się szczęśliwy i
              dumny. Wiedział, że trzy magiczne słowa - "proszę", "przepraszam"
              i "dziękuję" - mają moc, aby uczynić świat lepszym miejscem.
            </p>
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            <ul className={styles.buttonsWrapper}>
              {gestures.map((gesture) => {
                return (
                  <li key={gesture.pk} className={styles.buttonWrapper}>
                    <button
                      className={styles.button}
                      onClick={() => handleClick(gesture)}
                    >
                      {gesture.title}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <img src={Ue} className={styles.ue} alt="ue" />
      </div>
    </Layout>
  );
};
