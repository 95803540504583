import { configureStore } from "@reduxjs/toolkit";
import gesturesReducer from "./Gestures/gesturesSlice";
import notificationReducer from "./Notification/notificationSlice";
import groupReducer from "./Group/groupSlice";

const store = configureStore({
    reducer: {
        gestures: gesturesReducer,
        notification: notificationReducer,
        group: groupReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
