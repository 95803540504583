import React, { FC } from "react";

import { Layout } from "../../components/Layout/Layout";

import styles from "./LandingPage.module.scss";
import { LoginForm } from "../../components/LoginForm/LoginForm";

export const LoginPage: FC = () => {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
};
