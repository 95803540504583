import React, { FC } from "react";

import { LayoutProps } from "./Layout.types";

import styles from "./Layout.module.scss";

export const Layout: FC<LayoutProps> = ({
  children,
  skipPadding,
}: LayoutProps) => {
  return (
    <div className={skipPadding ? styles.wrapperSkipPadding : styles.wrapper}>
      {children}
    </div>
  );
};
