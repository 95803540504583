// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_wrapper__v90fz {
  display: flex;
  flex-direction: column;
}

.LoginForm_title__cvnUl {
  font-size: 42px;
  margin-bottom: 36px;
}

.LoginForm_form__Kx5pU {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.LoginForm_label__RGIMj {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #DEDAC6 !important;
  margin-bottom: 6px;
}

.LoginForm_loginButton__GHtvD {
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: #DEDAC6;
  padding: 16px;
  width: max-content;
  border-radius: 32px;
  min-width: 160px;
}
@media (min-width: 1025px) {
  .LoginForm_loginButton__GHtvD {
    align-self: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoginForm/LoginForm.module.scss","webpack://./src/styles/colors.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;AAFJ;;AAKA;EACI,eAAA;EACA,mBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAFJ;;AAKA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EACA,yBC9BQ;ED+BR,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAFJ;AEzBQ;EFkBR;IAYQ,oBAAA;EADN;AACF","sourcesContent":["@use '../../styles/colors' as colors;\r\n@use '../../styles/responsive' as rwd;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.title {\r\n    font-size: 42px;\r\n    margin-bottom: 36px;\r\n}\r\n\r\n.form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 32px;\r\n}\r\n\r\n.label {\r\n    font-size: 14px !important;\r\n    font-weight: 500 !important;\r\n    color: colors.$SECONDARY !important;\r\n    margin-bottom: 6px;\r\n}\r\n\r\n.loginButton {\r\n    display: flex;\r\n    justify-content: center;\r\n    cursor: pointer;\r\n    border: none;\r\n    background-color: colors.$SECONDARY;\r\n    padding: 16px;\r\n    width: max-content;\r\n    border-radius: 32px;\r\n    min-width: 160px;\r\n    \r\n    @include rwd.breakpoint(large) {\r\n        align-self: flex-end;\r\n    }\r\n}","$PRIMARY: #6E664A;\r\n$SECONDARY: #DEDAC6;","@mixin breakpoint($point) {\r\n    @if $point == small {\r\n        @media (max-width:  1024px) { @content; }\r\n    }\r\n    @else if $point == medium {\r\n        @media (min-width:  769px) and (max-width: 1280px) { @content; }\r\n    }\r\n    @else if $point == large {\r\n        @media (min-width:  1025px) { @content; }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoginForm_wrapper__v90fz`,
	"title": `LoginForm_title__cvnUl`,
	"form": `LoginForm_form__Kx5pU`,
	"label": `LoginForm_label__RGIMj`,
	"loginButton": `LoginForm_loginButton__GHtvD`
};
export default ___CSS_LOADER_EXPORT___;
