import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { 
    checkMatching,
    getGestures
 } from "../../api/requests";
import { Gesture, GestureMatching } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";
import { isAxiosError } from "axios";

interface UploadVideoParams {
    videoBlob: Blob;
    gesture: Gesture;
  }

export const sendGesture = createAsyncThunk(
    "gestures/sendGesture",
    async ({ videoBlob, gesture }: UploadVideoParams, { dispatch }) => {
      const formData = new FormData();
      formData.append("video_file", videoBlob);
      formData.append("gesture", gesture.pk.toString());
  
      try {
        const response = await checkMatching(formData);
        return response;
      } catch (e) {
        let errorMsg = "Wystąpił błąd. Spróbuj później.";
        if (isAxiosError(e) && e?.response?.data.detail) {
          errorMsg = e.response.data.detail;
        }
        dispatch(
          showNotification({
            variant: "error",
            title: "Error!",
            subtitle: errorMsg,
          })
        );
        console.error("Error uploading video", e);
        throw e;
      }
    }
  );

export const fetchGestures = createAsyncThunk("gestures/fetchGestures", async (_, { dispatch }) => {
    try {
        const res = await getGestures();
        return res;
    } catch (e) {
        let errorMsg = "Wystąpił błąd. Spróbuj później.";
        if (isAxiosError(e) && e?.response?.data.detail) {
            errorMsg = e.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(e);
        throw e;
    }
});

export interface GesturesState {
    gestures: Gesture[];
    currentGesture: Gesture;
    loading: boolean;
    sending: boolean;
    score: GestureMatching;
    error?: string;
}

export const initialState: GesturesState = {
    gestures: [],
    currentGesture: {} as Gesture,
    score: {} as GestureMatching,
    loading: false,
    sending: false,
    error: undefined,
};

const gesturesSlice = createSlice({
    name: "gestures",
    initialState,
    reducers: {
        setCurrentGesture: (state, action: PayloadAction<Gesture>) => {
            state.currentGesture = action.payload;
        },
        resetScore: (state) => {
            state.score = {} as GestureMatching;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGestures.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGestures.fulfilled, (state, action) => {
                state.loading = false;
                state.gestures = action.payload;
            })
            .addCase(fetchGestures.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(sendGesture.pending, (state) => {
                state.sending = true;
            })
            .addCase(sendGesture.fulfilled, (state, action) => {
                state.sending = false;
                state.score = action.payload;
            })
            .addCase(sendGesture.rejected, (state, action) => {
                state.sending = false;
                state.score.Score = 'Spróbuj jeszcze raz'; // FOR NOW
                state.error = action.error.message;
            });
    },
});

export const { setCurrentGesture, resetScore } = gesturesSlice.actions;
export default gesturesSlice.reducer;
