import React from "react";
import { TextField, Box, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./LoginForm.module.scss";
import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  username: Yup.string().email("Niepoprawny adres email").required("Wymagane"),
  password: Yup.string().required("Wymagane"),
});

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: { username: string; password: string }) => {
    navigate("/groups");
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Zaloguj się</p>
      <Box sx={{ mb: 2 }}>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className={styles.form}>
              <Field name="username">
                {({ field, meta }: FieldProps) => (
                  <div>
                    <InputLabel id="username" className={styles.label}>
                      Email
                    </InputLabel>
                    <TextField
                      {...field}
                      id="usernamel"
                      placeholder="email@email.com"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      sx={{
                        width: "320px",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#f3c51d",
                          },
                        },
                      }}
                      inputProps={{ style: { backgroundColor: "#f3c51d" } }}
                    />
                  </div>
                )}
              </Field>

              <Field name="password">
                {({ field, meta }: FieldProps) => (
                  <div>
                    <InputLabel id="password" className={styles.label}>
                      Hasło
                    </InputLabel>
                    <TextField
                      {...field}
                      id="password"
                      variant="outlined"
                      placeholder="********"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      autoComplete="current-password"
                      type="password"
                      // required
                      sx={{
                        width: "320px",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#f3c51d",
                          },
                        },
                      }}
                      inputProps={{ style: { backgroundColor: "#f3c51d" } }}
                    />
                  </div>
                )}
              </Field>
              <button className={styles.loginButton} type="submit">
                Zaloguj
              </button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};
