// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WordsPage_wrapper__n4dnn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.WordsPage_wrapperInner__CVfk7 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.WordsPage_buttonsWrapper__We4-e {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
}

.WordsPage_historyWrapper__yGCna {
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-size: 26px;
  margin-top: 16px;
}

.WordsPage_ue__NaF8G {
  max-width: 100%;
  height: auto;
}

.WordsPage_buttonWrapper__ARoiB {
  margin-bottom: 32px;
}

.WordsPage_title__NSB\\+e {
  font-size: 32px;
}

.WordsPage_button__728MQ {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  font-size: 28px;
  height: 96px;
  width: max-content;
  border-radius: 32px;
  width: 400px;
  text-wrap: nowrap;
  background-color: #E4AF16;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/pages/WordsPage/WordsPage.module.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAFJ;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAFJ;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,qBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,eAAA;EACA,YAAA;AAFJ;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,eAAA;AAFJ;;AAKA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,wCAAA;AAFJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n@use '../../styles/responsive' as rwd;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 64px;\r\n}\r\n\r\n.wrapperInner {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 64px;\r\n}\r\n\r\n.buttonsWrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 16px;\r\n    list-style-type: none;\r\n}\r\n\r\n.historyWrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 32px;\r\n    font-size: 26px;\r\n    margin-top: 16px;\r\n}\r\n\r\n.ue {\r\n    max-width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.buttonWrapper {\r\n    margin-bottom: 32px;\r\n}\r\n\r\n.title {\r\n    font-size: 32px;\r\n}\r\n\r\n.button {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    cursor: pointer;\r\n    border: none;\r\n    font-size: 28px;\r\n    height: 96px;\r\n    width: max-content;\r\n    border-radius: 32px;\r\n    width: 400px;\r\n    text-wrap: nowrap;\r\n    background-color: #E4AF16;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `WordsPage_wrapper__n4dnn`,
	"wrapperInner": `WordsPage_wrapperInner__CVfk7`,
	"buttonsWrapper": `WordsPage_buttonsWrapper__We4-e`,
	"historyWrapper": `WordsPage_historyWrapper__yGCna`,
	"ue": `WordsPage_ue__NaF8G`,
	"buttonWrapper": `WordsPage_buttonWrapper__ARoiB`,
	"title": `WordsPage_title__NSB+e`,
	"button": `WordsPage_button__728MQ`
};
export default ___CSS_LOADER_EXPORT___;
