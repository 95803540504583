import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import styles from "./Animal.module.scss";
import Konik from "../../assets/images/konik.png";
import Zyrafa from "../../assets/images/zyrafa.png";
import Krowa from "../../assets/images/krowa.png";
import Kot from "../../assets/images/kot.png";
import Pisesk from "../../assets/images/pisesk.png";
import Tygrys from "../../assets/images/tygrys.png";

const animalImages: Record<string, string> = {
  "Szybki Koń": Konik,
  "Elegancka Żyrafa": Zyrafa,
  "Wytrwała Krowa": Krowa,
  "Tajemniczy Kot": Kot,
  "Towarzyski Pies": Pisesk,
  "Zwinny Tygrys": Tygrys,
};

const animalBackgrounds: Record<string, string> = {
  "Szybki Koń": "#E4AF16",
  "Elegancka Żyrafa": "#A36379",
  "Wytrwała Krowa": "#5B854B",
  "Tajemniczy Kot": "#69859E",
  "Towarzyski Pies": "#7D5E8A",
  "Zwinny Tygrys": "#B14A3E",
};

const Animal: React.FC = () => {
  const selectedAnimal = useSelector(
    (state: RootState) => state.group.selectedGroup
  );

  const selectedGesture = useSelector(
    (state: RootState) => state.gestures.currentGesture
  );

  if (!selectedAnimal) {
    return <p>No animal selected.</p>;
  }

  const animalImage = animalImages[selectedAnimal];
  const backgroundColor = animalBackgrounds[selectedAnimal];

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        style={{ backgroundColor: backgroundColor }}
      >
        <img src={animalImage} alt={selectedAnimal} className={styles.image} />
        <p>{selectedAnimal}</p>
      </div>
      <p className={styles.title} style={{ backgroundColor: backgroundColor }}>
        {selectedGesture.title}
      </p>
    </div>
  );
};

export default Animal;
