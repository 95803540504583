import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GroupState {
  selectedGroup: string | null;
}

const initialState: GroupState = {
  selectedGroup: null,
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setSelectedGroup(state, action: PayloadAction<string>) {
      state.selectedGroup = action.payload;
    },
  },
});

export const { setSelectedGroup } = groupSlice.actions;
export default groupSlice.reducer;