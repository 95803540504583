// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Animal_wrapper__rAmkz {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.Animal_container__HiUIr {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  border: none;
  font-size: 28px;
  width: max-content;
  border-radius: 42px;
  padding: 2px 0;
  width: 350px;
  text-wrap: nowrap;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Animal_title__42d21 {
  display: flex;
  align-items: center;
  font-size: 28px;
  border-radius: 42px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.Animal_image__VlJSG {
  max-height: 64px;
}`, "",{"version":3,"sources":["webpack://./src/components/Animal/Animal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,wCAAA;AACJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,wCAAA;EACA,aAAA;AACF;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".wrapper {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 24px;\r\n}\r\n\r\n.container {\r\n    display: flex;\r\n    align-items: center;\r\n    flex-direction: row;\r\n    gap: 20px;\r\n    border: none;\r\n    font-size: 28px;\r\n    width: max-content;\r\n    border-radius: 42px;\r\n    padding: 2px 0;\r\n    width: 350px;\r\n    text-wrap: nowrap;\r\n    position: relative;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.title {\r\n  display: flex;\r\n  align-items: center;\r\n  font-size: 28px;\r\n  border-radius: 42px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n  padding: 16px;\r\n}\r\n  \r\n.image {\r\n    max-height: 64px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Animal_wrapper__rAmkz`,
	"container": `Animal_container__HiUIr`,
	"title": `Animal_title__42d21`,
	"image": `Animal_image__VlJSG`
};
export default ___CSS_LOADER_EXPORT___;
