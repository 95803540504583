import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LandingPage } from "./LandingPage/LandingPage";
import { LoginPage } from "./LoginPage/LoginPage";
import { RecordPage } from "./RecordPage/RecordPage";
import { GroupsPage } from "./GroupsPage/GroupsPage";
import { WordsPage } from "./WordsPage/WordsPage";

const Index: React.FunctionComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/groups" element={<GroupsPage />} />
        <Route path="/words" element={<WordsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recorder" element={<RecordPage />} />
        <Route path="*" element={<h1>Error 404, źle trafiłeś!</h1>} />
      </Routes>
    </Router>
  );
};

export default Index;
