import { Gesture, GestureMatching } from "./types";
import { URLS } from "./urls";
import api from "../store/Auth_User/authService";

export const getGestures = (): Promise<Gesture[]> =>
    api
        .get(URLS.GESTURES)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

        export const checkMatching = (data: FormData): Promise<GestureMatching> =>
            api
                .post(URLS.GESTURES_CHECK, data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    throw error;
                });