// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;    
  font-family: 'CenturyGothicPaneuropeanRegular', sans-serif;
  background-color: #DEDAC6;
}

p {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,0DAA0D;EAC1D,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["body {\n  margin: 0;\n  padding: 0;    \n  font-family: 'CenturyGothicPaneuropeanRegular', sans-serif;\n  background-color: #DEDAC6;\n}\n\np {\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
