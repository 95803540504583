import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

export interface NotificationState {
    isVisible?: boolean;
    variant: "success" | "error";
    title: string;
    subtitle?: string;
}

export const initialState: NotificationState = {
    isVisible: false,
    variant: "success",
    title: "",
    subtitle: "",
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<NotificationState>) => {
            state.isVisible = true;
            state.variant = action.payload.variant;
            state.title = action.payload.title;
            state.subtitle = action.payload.subtitle;
        },
        hideNotification: (state) => {
            state.isVisible = false;
        },
    },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export const selectNotification = (state: RootState) => state.notification;

export default notificationSlice.reducer;
