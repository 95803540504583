import React, { FC, useState, useRef, useEffect } from "react";
import RecordRTC from "recordrtc";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { Layout } from "../../components/Layout/Layout";
import { CircularProgress } from "@mui/material";
import styles from "./RecordPage.module.scss";
import { resetScore, sendGesture } from "../../store/Gestures/gesturesSlice";
import { useNavigate } from "react-router-dom";
import Animal from "../../components/Animal/Animal";

export const RecordPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { currentGesture, sending, score } = useSelector(
    (state: RootState) => state.gestures
  );

  const { selectedGroup } = useSelector((state: RootState) => state.group);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    if (!currentGesture || !selectedGroup) {
      navigate("/groups");
    }
  }, [currentGesture, dispatch, navigate, selectedGroup]);

  const handleGoGroups = () => {
    dispatch(resetScore());
    navigate("/groups");
  };

  const handleGoWords = () => {
    dispatch(resetScore());
    navigate("/words");
  };

  const startCamera = async () => {
    dispatch(resetScore());
    setIsRecording(true);
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { max: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30 },
        },
      });
      setStream(mediaStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error("Error accessing the camera", error);
    }
  };

  const startRecording = () => {
    if (stream) {
      const recorderInstance = new RecordRTC(stream, {
        type: "video",
        mimeType: "video/webm",
        bitsPerSecond: 256000,
      });

      recorderInstance.startRecording();
      setRecorder(recorderInstance);
      setRecording(true);
    }
  };

  const stopRecordingAndUpload = async () => {
    if (recorder) {
      await recorder.stopRecording(async () => {
        const blob = recorder.getBlob();
        await dispatch(
          sendGesture({ videoBlob: blob, gesture: currentGesture })
        );
      });

      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      setStream(null);
      setRecording(false);
    }
  };

  const getResultColor = (text: string) => {
    switch (text) {
      case "Bardzo dobrze":
        return "#054D05";
      case "Dobrze":
        return "#008000";
      case "Nieźle":
        return "#6DE96D";
      case "Spróbuj jeszcze raz":
        return "#BA9E00";
    }
  };

  return (
    <Layout skipPadding>
      <div className={styles.container}>
        <div className={styles.topbarWrapper}>
          <Animal />
        </div>

        {!isRecording ? (
          <>
            <video controls className={styles.video}>
              <source
                src={currentGesture.reference_video_url}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div className={styles.actionButtonsWrapper}>
              <button className={styles.button} onClick={handleGoGroups}>
                Inny zwierzak
              </button>
              <button className={styles.button} onClick={handleGoWords}>
                Inny znak
              </button>
              <button className={styles.button} onClick={startCamera}>
                Spróbuj sam
              </button>
            </div>
          </>
        ) : (
          <>
            {sending ? (
              <div className={styles.circularProgressWrapper}>
                <CircularProgress />
              </div>
            ) : score.Score ? (
              <p className={styles.scoreWrapper}>
                <span style={{ color: getResultColor(score.Score) }}>
                  {score.Score}
                </span>
              </p>
            ) : (
              <video
                ref={videoRef}
                className={styles.video}
                autoPlay
                muted
                width="100%"
                height="100%"
              />
            )}
            <>
              {!stream && !isRecording ? (
                <div className={styles.actionButtonsWrapper}>
                  <button className={styles.button} onClick={handleGoGroups}>
                    Inny zwierzak
                  </button>
                  <button className={styles.button} onClick={handleGoWords}>
                    Inny znak
                  </button>
                  <button className={styles.button} onClick={startCamera}>
                    Włącz kamerę
                  </button>
                </div>
              ) : recording ? (
                <div className={styles.actionButtonsWrapper}>
                  <button
                    className={styles.button}
                    onClick={stopRecordingAndUpload}
                  >
                    Zakończ nagrywanie
                  </button>
                </div>
              ) : (
                <div className={styles.actionButtonsWrapper}>
                  {score.Score ? (
                    <>
                      <button
                        className={styles.button}
                        onClick={handleGoGroups}
                      >
                        Inny zwierzak
                      </button>
                      <button className={styles.button} onClick={handleGoWords}>
                        Inny znak
                      </button>
                      <button className={styles.button} onClick={startCamera}>
                        Włącz kamerę
                      </button>
                    </>
                  ) : (
                    <button className={styles.button} onClick={startRecording}>
                      Zacznij nagrywać
                    </button>
                  )}
                </div>
              )}
            </>
          </>
        )}
      </div>
    </Layout>
  );
};
