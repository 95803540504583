import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { Layout } from "../../components/Layout/Layout";

import styles from "./GroupsPage.module.scss";
import Konik from "../../assets/images/konik.png";
import Kot from "../../assets/images/kot.png";
import Krowa from "../../assets/images/krowa.png";
import Pisesk from "../../assets/images/pisesk.png";
import Tygrys from "../../assets/images/tygrys.png";
import Zyrafa from "../../assets/images/zyrafa.png";
import Ue from "../../assets/images/ue.png";
import { setSelectedGroup } from "../../store/Group/groupSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

export const GroupsPage: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClick = (animal: string) => {
    dispatch(setSelectedGroup(animal));
    navigate("/words");
  };

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <ul className={styles.buttonsWrapper}>
            <li>
              <button
                className={`${styles.button} ${styles["bg-konik"]}`}
                onClick={() => handleClick("Szybki Koń")}
              >
                <img src={Konik} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Szybki Koń</span>
              </button>
            </li>
            <li>
              <button
                className={`${styles.button} ${styles["bg-zyrafa"]}`}
                onClick={() => handleClick("Elegancka Żyrafa")}
              >
                <img src={Zyrafa} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Elegancka Żyrafa</span>
              </button>
            </li>
            <li>
              <button
                className={`${styles.button} ${styles["bg-krowa"]}`}
                onClick={() => handleClick("Wytrwała Krowa")}
              >
                <img src={Krowa} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Wytrwała Krowa</span>
              </button>
            </li>
            <li>
              <button
                className={`${styles.button} ${styles["bg-kot"]}`}
                onClick={() => handleClick("Tajemniczy Kot")}
              >
                <img src={Kot} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Tajemniczy Kot</span>
              </button>
            </li>
            <li>
              <button
                className={`${styles.button} ${styles["bg-pies"]}`}
                onClick={() => handleClick("Towarzyski Pies")}
              >
                <img src={Pisesk} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Towarzyski Pies</span>
              </button>
            </li>
            <li>
              <button
                className={`${styles.button} ${styles["bg-tygrys"]}`}
                onClick={() => handleClick("Zwinny Tygrys")}
              >
                <img src={Tygrys} className={styles.image} alt="logo" />
                <span className={styles.buttonText}>Zwinny Tygrys</span>
              </button>
            </li>
          </ul>
          <div className={styles.historyWrapper}>
            <p>
              W małym, urokliwym królestwie zwanym Grzecznolandią żył lisek o
              imieniu MIGUŚ. Królestwo to było wyjątkowe, ponieważ mieszkańcy
              codziennie używali trzech magicznych słów, które sprawiały, że
              każdy dzień był pełen szczęścia i harmonii. Te słowa to "proszę",
              "przepraszam" i "dziękuję".
            </p>
            <p>
              Pewnego dnia Miguś wyruszyć w podróż po królestwie, aby
              przypomnieć wszystkim o mocy tych słów. Pierwszym przystankiem
              była wieś na skraju lasu, gdzie spotkała małego chłopca imieniem
              Janek, który próbował zbudować zamek z piasku.
            </p>
            <p>
              Janek był zmartwiony, bo jego zamek wciąż się rozpadał. Miguś
              powiedział: "Proszę, pokaż mi, jak budujesz swój zamek. Może mogę
              ci pomóc." Janek z radością zgodził się i razem zbudowali
              najpiękniejszy zamek, jaki kiedykolwiek widział. Chłopiec
              powiedział: "Dziękuję, Migusiu! Dzięki tobie mój zamek jest
              wspaniały."
            </p>
          </div>
        </div>
        <img src={Ue} className={styles.ue} alt="ue" />
      </div>
    </Layout>
  );
};
