import axios, { AxiosRequestConfig } from "axios";

import { URLS } from "../../api/urls";
import { Tokens } from "../../api/types";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
    headers: {
        Authorization?: string;
    };
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

api.interceptors.request.use(
    async (config: any) => {
        const newConfig: InternalAxiosRequestConfig = { ...config };

        const user = JSON.parse(localStorage.getItem("user") ?? "{}") as Tokens;

        if (user.access && user.access !== "undefined") {
            config.headers.Authorization = `Bearer ${user.access}`;
        } else {
            return config;
        }

        return newConfig;
    },
    (error) => Promise.reject(error),
);

//@ts-ignore
let refreshingFunc = undefined;

api.interceptors.response.use(
    (response) => {
        return response;
    },

    async (error) => {
        const originalRequest = error.config;

        const user = JSON.parse(localStorage.getItem("user") ?? "{}") as Tokens;

        if (!user.refresh) {
            return Promise.reject(error);
        }
        const isLoginRequest = originalRequest.url.includes("/login");
        try {
            //@ts-ignore
            if (error.response.status === 401 && !refreshingFunc && !isLoginRequest) {
                const refreshToken = user.refresh;

                refreshingFunc = await axios.post(`${process.env.REACT_APP_API_BASEURL}${URLS.REFRESH_TOKEN}`, {
                    refresh: refreshToken,
                });
                const newUserData: Tokens = {
                    access: refreshingFunc.data.access,
                    refresh: refreshingFunc.data.refresh,
                };
                localStorage.setItem("user", JSON.stringify(newUserData));
                originalRequest.headers["Authorization"] = `Bearer ${refreshingFunc.data.access}`;
                return api(originalRequest);
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem("user");
            throw error;
        } finally {
            refreshingFunc = undefined;
        }

        return Promise.reject(error);
    },
);

export default api;
