import React, { FC } from "react";

import { Layout } from "../../components/Layout/Layout";
import Lis from "../../assets/images/lis.png";
import Ue from "../../assets/images/ue.png";

import styles from "./LandingPage.module.scss";
import { LoginForm } from "../../components/LoginForm/LoginForm";

export const LandingPage: FC = () => {
  return (
    <div className={styles.outerWrapper}>
      <Layout skipPadding>
        <div className={styles.wrapper}>
          <div className={styles.titleFormWrapper}>
            <p className={styles.title}>MIGUŚ</p>
            <LoginForm />
          </div>
          <div className={styles.logoWrapper}>
            <img src={Lis} className={styles.logo} alt="logo" />
            <img src={Ue} className={styles.ue} alt="ue" />
          </div>
        </div>
      </Layout>
    </div>
  );
};
